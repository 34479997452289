import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateValues } from "./useStateValue";
import { Transport, User } from "../../types";
import { capitalizeFirstLetter, convertUTCToLocal } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { TablePagination, TextField } from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch } from "react-redux";
import "./index.scss";
import { PlaylistAddCircleOutlined } from "@mui/icons-material";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { filterUserApi } from "../User/api";
const CallDashboard = () => {
  const dispatch = useDispatch();
  const {
    transport_call,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateTransportCall,
    order_by,
    updateSortKey,
  } = useStateValues();

  const columns = [
    { name: "Id", sort_key: "id", width: "100px" },
    { name: "Created", sort_key: "created" },
    { name: "Transport", sort_key: "", width: "200px" },
    { name: "Phone", sort_key: "", width: "200px" },
    { name: "Called By", sort_key: "added_by.id", width: "150px" },
    { name: "Status", sort_key: "", width: "100px" },
    { name: "Comment", sort_key: "", width: "300px" },
  ];

  return (
    <div className="p-8">
      <div className="d-flex gap-8 flex-wrap">
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Transport Name"
          variant="outlined"
          onChange={(e) => updateFilter("transport_name", e.target.value)}
        />
        <TextField
          size="small"
          className="bg-neutral-2 "
          placeholder="Status"
          variant="outlined"
          onChange={(e) => updateFilter("status", e.target.value)}
        />
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(user: CustomAutoCompleteSelected<User>) => {
            if (user) {
              updateFilter("added_by", (user as User).id);
            } else {
              updateFilter("added_by", null);
            }
          }}
          getOptionLabel={(user: User) => {
            return capitalizeFirstLetter(user.first_name);
          }}
          placeholder="Added By"
          renderOptionFn={(user: User) => <UserAvatar user={user} />}
          dataSource={(text: string) => filterUserApi({ text })}
        />
      </div>
      <div className="mt-8">
        <TableContainer sx={{ backgroundColor: "white" }} component={Paper}>
          <div className="d-flex gap-16 flex-wrap align-items-center px-16">
            <PlaylistAddCircleOutlined
              onClick={() =>
                dispatch(setAddEntity({ key: "call", value: true }))
              }
              className="orange-3 pointer"
            />
            <TablePagination
              rowsPerPageOptions={transport_call.length ? [10, 20, 50] : []}
              component="div"
              count={count}
              rowsPerPage={page_size}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(e) =>
                setPageSize(parseInt(e.target.value, 10))
              }
            />
          </div>
          <Table size="small" className="load-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.name}
                    align="left"
                    width={column.width || "100px"}
                  >
                    <div
                      className="d-flex gap-8 align-items-center pointer"
                      onClick={(e) => {
                        updateSortKey(column.sort_key);
                      }}
                    >
                      <span>{column.name}</span>
                      {column.sort_key.length &&
                      order_by.includes(column.sort_key) ? (
                        <span>
                          <SwapVertIcon />
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transport_call.map((call, index) => (
                <React.Fragment key={call.id}>
                  <TableRow
                    key={call.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{call.id}</TableCell>
                    <TableCell align="left">
                      {convertUTCToLocal(call.created!, {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: '2-digit',
                        minute: '2-digit',
                        hour12: true
                      })}
                    </TableCell>
                    <TableCell align="left">
                      {(call.transport as Transport)?.name}
                    </TableCell>
                    <TableCell align="left">
                      {(call.transport as Transport)?.phone}
                    </TableCell>
                    <TableCell align="left">
                      <UserAvatar user={call.added_by as User} />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Call Status"
                        variant="outlined"
                        type="text"
                        defaultValue={call.status}
                        onChange={(e) =>
                          updateTransportCall({
                            index,
                            key: "rating",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Commnet"
                        variant="outlined"
                        type="text"
                        defaultValue={call.comment}
                        onChange={(e) =>
                          updateTransportCall({
                            index,
                            key: "comment",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default CallDashboard;
