import { Data } from "../../types";

export interface TripStatus extends Data {}
export const TRIP_STATUS: TripStatus[] = [
  {
    id: "SCHEDULED",
  },
  {
    id: "TRANSIT",
  },
  {
    id: "COMPLETE",
  },
  {
    id: "CANCELED",
  },
];
