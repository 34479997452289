import { Button, Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useStateValues } from "./useStateValues";
import { axiosInstance } from "../../../api";
import { useDispatch } from "react-redux";
import { Transport, TransportCall } from "../../types";
import { setMessage } from "../../../redux/slices/alertSlice";
import { TextField } from "@mui/material";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import { Add } from "@mui/icons-material";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { capitalizeFirstLetter } from "../../util/utils";
import UserAvatar from "../../common/UserAvatar";
import { searchTranportApi } from "../TransportDashboard/api";

export const AddTransportCall = ({ onClose }: { onClose?: () => void }) => {
  const dispatch = useDispatch();

  const { transport_call, updateTransportCall, errorConfig, validate } =
    useStateValues();

  const handleTransportCallAdd = async () => {
    if (validate()) {
      console.log("not validate here", errorConfig);
      return;
    }
    const data = {
      ...transport_call,
    };
    try {
      await axiosInstance().post<TransportCall>(
        "backoffice/transport_call/",
        data
      );
      dispatch(setMessage("Call Added"));
      onClose?.();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="bg-neutral-3 d-flex p-16 align-items-center">
        <div className="fs-18 flex-grow">Add Transport Call</div>
        <div className="pointer" onClick={onClose}>
          <CloseIcon />
        </div>
      </div>
      <div className="p-16 overflow-y-scroll" style={{ height: "70vh" }}>
        <div className="d-flex gap-8">
          <Button
            className="d-flex bg-orange-4 neutral-1"
            onClick={() =>
              dispatch(setAddEntity({ key: "transport", value: true }))
            }
          >
            <Add />
            <span>Transport</span>
          </Button>
        </div>
        <CustomAutoComplete
          className="mt-8"
          width={"200px"}
          onSelect={(transport: CustomAutoCompleteSelected<Transport>) => {
            if (transport) {
              updateTransportCall(
                "transport",
                (transport as Transport).id as number
              );
            }
          }}
          getOptionLabel={(transport: Transport) => {
            return capitalizeFirstLetter(transport.name);
          }}
          placeholder="Transport"
          renderOptionFn={(transport: Transport) => (
            <UserAvatar user={{ first_name: transport.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
          errorMessage={errorConfig["load_owner_id"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Status"
          variant="outlined"
          onChange={(e) => updateTransportCall("status", e.target.value)}
          helperText={errorConfig["status"]}
        />
        <TextField
          fullWidth
          size="small"
          className="mt-8"
          label="Comment"
          variant="outlined"
          onChange={(e) => updateTransportCall("comment", e.target.value)}
          helperText={errorConfig["comment"]}
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox                
                onChange={(e) => updateTransportCall("is_active", e.target.checked)}
              />
            }
            label="Transport Active"
          />
        </FormGroup>
      </div>
      <div className="p-16 mt-16">
        <div className="d-flex gap-8 justify-content-end">
          <Button
            className="flex-half"
            color="primary"
            variant="contained"
            onClick={handleTransportCallAdd}
          >
            Add Call
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddTransportCall;
