import { useCallback, useEffect, useState } from "react";
import { Address, Load, Map, Trip } from "../../types";
import {
  UpdateEntityDebounceIndexFn,
  UpdateEntityFn,
} from "../../common/AddTransport/types";
import { updateTripApi, listTripApi } from "./api";
import { debounce } from "../../util/debounce";

export const useStateValues = () => {
  const [trips, setTrips] = useState<Trip[]>([]);
  const [page, setPage] = useState(0);
  const [page_size, setPageSize] = useState(50);
  const [count, setCount] = useState(0);
  const [order_by, setOrderBy] = useState("-created");
  const [filter, setFilter] = useState<Map>({
    status: "TRANSIT,SCHEDULED"
  });
  const updateFilter: UpdateEntityFn<Map> = useCallback(
    (key, value) => {
      const newFilters = { ...filter };
      newFilters[key] = value;
      setFilter(newFilters);
    },
    [filter]
  );
  const getTrips = useCallback(() => {
    listTripApi({
      filter,
      page: page + 1,
      page_size,
      order_by,
    }).then((data) => {
      setTrips(data.results);
      setCount(data.count);
    });
  }, [filter, order_by, page, page_size]);
  useEffect(() => {
    getTrips();
  }, [filter, getTrips]);
  const updateTrip: UpdateEntityDebounceIndexFn<Trip> = ({
    index,
    key,
    value,
  }) => {
    const newTrips = [...trips];
    newTrips[index][key] = value;
    setTrips(newTrips);
    updateTripApi(trips[index].id as number, {
      [key]: value,
    });
  };

  const updateSortKey = (sort_key: string) => {
    if (sort_key?.length) {
      if (order_by.includes(sort_key)) {
        if (order_by === sort_key) {
          setOrderBy(`-${sort_key}`);
        } else {
          setOrderBy(sort_key);
        }
      } else {
        setOrderBy(sort_key);
      }
    }
  };
  return {
    trips,
    setTrips,
    filter,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateTrip: debounce(updateTrip),
    order_by,
    updateSortKey,
  };
};
