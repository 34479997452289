import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useStateValues } from "./useStateValue";
import CustomAutoComplete from "../../common/CustomAutoComplete";
import { Address, Transport } from "../../types";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import { capitalizeFirstLetter, convertUTCToLocal } from "../../util/utils";
import { searchTranportApi } from "./api";
import UserAvatar from "../../common/UserAvatar";
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  TablePagination,
  TextField,
} from "@mui/material";
import SwapVertIcon from "@mui/icons-material/SwapVert";
import { useDispatch } from "react-redux";
import { setAddEntity } from "../../../redux/slices/entitySlice";
import "./index.scss";
import { CustomAutoCompleteSelected } from "../../common/CustomAutoComplete/types";
import { filterCityApi } from "../AddAddress/api";
const TransportDashboard = () => {
  const dispatch = useDispatch();
  const {
    filter,
    loader,
    transports,
    updateFilter,
    page,
    setPage,
    page_size,
    setPageSize,
    count,
    updateTransport,
    order_by,
    updateSortKey,
    updateAddress,
  } = useStateValues();

  const columns = [
    { name: "Id", sort_key: "id", width: "50px" },
    { name: "Name", sort_key: "name", width: "300px" },
    { name: "Created", sort_key: "created" },
    { name: "City", sort_key: "address.city", width: "200px" },
    { name: "State", sort_key: "address.state", width: "200px" },
    { name: "Latitude", sort_key: "", width: "200px" },
    { name: "Longitude", sort_key: "", width: "200px" },
    { name: "Pincode", sort_key: "address.pincode", width: "200px" },
    { name: "Phone", sort_key: "phone", width: "200px" },
    { name: "Distance", sort_key: "" },
    { name: "Active", sort_key: "" },
    { name: "Total Transaction", sort_key: "" },
    { name: "Fraud Amount", sort_key: "" },
    { name: "Rating", sort_key: "rating" },
    { name: "Reviews", sort_key: "" },
  ];
  return (
    <div className="p-8">
      <div className="d-flex gap-8 flex-wrap">
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Transport Name (Text)"
          variant="outlined"
          onChange={(e) =>
            updateFilter({ key: "transport_name", value: e.target.value })
          }
        />
        <CustomAutoComplete
          className=""
          width={"200px"}
          onSelect={(transport: Transport | Transport[] | null) => {
            if (transport) {
              updateFilter({ key: "id", value: (transport as Transport).id });
            } else {
              updateFilter({ key: "id", value: null });
            }
          }}
          getOptionLabel={(transport: Transport) => {
            console.log(transport);
            return capitalizeFirstLetter(transport.name);
          }}
          placeholder="Transport"
          renderOptionFn={(transport: Transport) => (
            <UserAvatar user={{ first_name: transport.name }} />
          )}
          dataSource={(text: string) => searchTranportApi({ name: text })}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="City"
          variant="outlined"
          onChange={(e) => updateFilter({ key: "city", value: e.target.value })}
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="State"
          variant="outlined"
          onChange={(e) =>
            updateFilter({ key: "state", value: e.target.value })
          }
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Phone"
          variant="outlined"
          onChange={(e) =>
            updateFilter({ key: "phone", value: e.target.value })
          }
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Rating"
          variant="outlined"
          type="number"
          onChange={(e) =>
            updateFilter({ key: "rating", value: Number(e.target.value) })
          }
        />
        <TextField
          size="small"
          className=" bg-neutral-2 "
          placeholder="Pincode"
          variant="outlined"
          type="number"
          onChange={(e) =>
            updateFilter({ key: "pincode", value: Number(e.target.value) })
          }
        />
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.is_active as boolean}
                onChange={(e) =>
                  updateFilter({ key: "is_active", value: e.target.checked })
                }
              />
            }
            label="Is Active"
          />
        </FormGroup>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={filter.near_by_active as boolean}
                onChange={(e) => {
                  updateFilter({
                    key: "near_by_active",
                    value: e.target.checked,
                  });
                }}
              />
            }
            label="Near By"
          />
        </FormGroup>
        {loader && <CircularProgress size={"small"} />}
      </div>
      <div>
        {(filter.near_by_active as boolean) ? (
          <div>
            <div>Near By Search</div>
            <div className="d-flex gap-8">
              <TextField
                size="small"
                className=" bg-neutral-2 "
                placeholder="Near By"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  updateFilter({
                    key: "distance",
                    value: Number(e.target.value),
                  })
                }
              />
              <CustomAutoComplete
                className=""
                width={"200px"}
                onSelect={(address: CustomAutoCompleteSelected<Address>) => {
                  if (address) {
                    updateFilter({
                      key: "city_id",
                      value: (address as Address).id,
                    });
                  } else {
                    updateFilter({ key: "city_id", value: null });
                  }
                }}
                getOptionLabel={(address: Address) => address.city}
                placeholder="City"
                renderOptionFn={(address: Address) => <div>{address.city}</div>}
                dataSource={(text: string) => filterCityApi({ city: text })}
              />
              <TextField
                size="small"
                className=" bg-neutral-2 "
                placeholder="Latitude"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  updateFilter({ key: "lat", value: Number(e.target.value) })
                }
              />
              <TextField
                size="small"
                className=" bg-neutral-2 "
                placeholder="Longitude"
                variant="outlined"
                type="number"
                onChange={(e) =>
                  updateFilter({ key: "lng", value: Number(e.target.value) })
                }
              />
            </div>
          </div>
        ) : null}
      </div>
      <div className="mt-8">
        <TableContainer sx={{ backgroundColor: "white" }} component={Paper}>
          <div className="d-flex gap-16 flex-wrap align-items-center px-16">
            <PlaylistAddIcon
              onClick={() =>
                dispatch(setAddEntity({ key: "transport", value: true }))
              }
              className="orange-3 pointer"
            />
            <TablePagination
              rowsPerPageOptions={transports.length ? [10, 20, 50] : []}
              component="div"
              count={count}
              rowsPerPage={page_size}
              page={page}
              onPageChange={(_, page) => setPage(page)}
              onRowsPerPageChange={(e) =>
                setPageSize(parseInt(e.target.value, 10))
              }
            />
          </div>
          <Table size="small" className="transport-table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.name}
                    align="left"
                    width={column.width}
                  >
                    <div
                      className="d-flex gap-8 align-items-center pointer"
                      onClick={() => {
                        updateSortKey(column.sort_key);
                      }}
                    >
                      <span>{column.name}</span>
                      {column.sort_key.length &&
                      order_by.includes(column.sort_key) ? (
                        <span>
                          <SwapVertIcon />
                        </span>
                      ) : null}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {transports.map((transport, index) => (
                <React.Fragment key={transport.id}>
                  <TableRow
                    key={transport.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="left">{transport.id}</TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Name"
                        variant="outlined"
                        type="text"
                        defaultValue={transport.name}
                        onChange={(e) =>
                          updateTransport({
                            index,
                            key: "name",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {convertUTCToLocal(transport.created!)}
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="City"
                        variant="outlined"
                        type="text"
                        defaultValue={transport.address?.city}
                        onChange={(e) =>
                          updateAddress({
                            index,
                            key: "city",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="State"
                        variant="outlined"
                        type="text"
                        defaultValue={transport.address?.state}
                        onChange={(e) =>
                          updateAddress({
                            index,
                            key: "state",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Address"
                        variant="outlined"
                        type="number"
                        defaultValue={transport.address?.latitude}
                        onChange={(e) =>
                          updateAddress({
                            index,
                            key: "latitude",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Address"
                        variant="outlined"
                        type="number"
                        defaultValue={transport.address?.longitude}
                        onChange={(e) =>
                          updateAddress({
                            index,
                            key: "longitude",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Pincode"
                        variant="outlined"
                        type="text"
                        defaultValue={transport.address?.pincode}
                        onChange={(e) =>
                          updateAddress({
                            index,
                            key: "pincode",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      <TextField
                        fullWidth
                        sx={{ width: "200px" }}
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Phone"
                        variant="outlined"
                        type="text"
                        defaultValue={transport.phone}
                        onChange={(e) =>
                          updateTransport({
                            index,
                            key: "phone",
                            value: e.target.value,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {Number(transport.distance_km as Number) > 0
                        ? `${Number(transport.distance_km).toFixed(0)} Km`
                        : "N/A"}
                    </TableCell>
                    <TableCell>
                      <Checkbox
                        checked={transport.is_active as boolean}
                        onChange={(e) =>
                          updateTransport({
                            index,
                            key: "is_active",
                            value: e.target.checked,
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {transport.transaction_amount}
                    </TableCell>
                    <TableCell align="left">{transport.fraud_amount}</TableCell>
                    <TableCell align="left">
                      <TextField
                        size="small"
                        className=" bg-neutral-2 "
                        placeholder="Rating"
                        variant="outlined"
                        type="number"
                        defaultValue={transport.rating}
                        onChange={(e) =>
                          updateTransport({
                            index,
                            key: "rating",
                            value: Number(e.target.value),
                          })
                        }
                      />
                    </TableCell>
                    <TableCell align="left">
                      {transport.reviews_count}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
};

export default TransportDashboard;
